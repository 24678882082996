define("discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="reviewable-filter reviewable-filter-endorsed-username-to-filter">
    <label class="filter-label">{{i18n "review.endorsed_username"}}</label>
    <EmailGroupUserChooser
      @value={{this.additionalFilters.endorsed_username}}
      @onChange={{action "updateEndorsedUsername"}}
      @autocomplete="off"
      @options={{hash fullWidthWrap=true maximum=1}}
    />
  </div>
  */
  {
    "id": "Sk1BvjPf",
    "block": "[[[10,0],[14,0,\"reviewable-filter reviewable-filter-endorsed-username-to-filter\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"filter-label\"],[12],[1,[28,[35,0],[\"review.endorsed_username\"],null]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@onChange\",\"@autocomplete\",\"@options\"],[[30,0,[\"additionalFilters\",\"endorsed_username\"]],[28,[37,2],[[30,0],\"updateEndorsedUsername\"],null],\"off\",[28,[37,3],null,[[\"fullWidthWrap\",\"maximum\"],[true,1]]]]],null],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"email-group-user-chooser\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/templates/connectors/above-review-filters/endorsed-username-to-filters.hbs",
    "isStrictMode": false
  });
});