define("discourse/plugins/discourse-category-experts/discourse/components/reviewable-category-expert-suggestion", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="row">
    <LinkTo @route="user" @model={{this.reviewable.user.username}}>
      {{avatar this.reviewable.user imageSize="small"}}
      {{this.reviewable.user.username}}
    </LinkTo>
    <CountI18n
      @key="category_experts.review.endorsed_count"
      @count={{this.reviewable.endorsed_count}}
    />
    {{category-link this.reviewable.category}}
  </div>
  
  <div class="row">
    <table class="endorsed-by-table">
      <thead>
        <th>
          <td>{{i18n
              "category_experts.review.endorsed_by"
              count=this.reviewable.endorsed_count
            }}</td>
        </th>
      </thead>
      <tbody>
        {{#each this.reviewable.endorsed_by as |user|}}
          <tr>
            <td class="endorsed-by">
              <LinkTo @route="user" @model={{user.username}}>
                {{avatar user imageSize="tiny"}}
                {{user.username}}
              </LinkTo>
            </td>
          </tr>
        {{/each}}
      </tbody>
    </table>
  </div>
  */
  {
    "id": "m7qYE8jl",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"user\",[30,0,[\"reviewable\",\"user\",\"username\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"reviewable\",\"user\"]]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n    \"],[1,[30,0,[\"reviewable\",\"user\",\"username\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,2],null,[[\"@key\",\"@count\"],[\"category_experts.review.endorsed_count\",[30,0,[\"reviewable\",\"endorsed_count\"]]]],null],[1,\"\\n  \"],[1,[28,[35,3],[[30,0,[\"reviewable\",\"category\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"endorsed-by-table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"\\n        \"],[10,\"td\"],[12],[1,[28,[35,4],[\"category_experts.review.endorsed_by\"],[[\"count\"],[[30,0,[\"reviewable\",\"endorsed_count\"]]]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"reviewable\",\"endorsed_by\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[14,0,\"endorsed-by\"],[12],[1,\"\\n            \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"user\",[30,1,[\"username\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[35,1],[[30,1]],[[\"imageSize\"],[\"tiny\"]]]],[1,\"\\n              \"],[1,[30,1,[\"username\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"user\"],false,[\"link-to\",\"avatar\",\"count-i18n\",\"category-link\",\"i18n\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/components/reviewable-category-expert-suggestion.hbs",
    "isStrictMode": false
  });
  class ReviewableCategoryExpertSuggestion extends _component.default {}
  _exports.default = ReviewableCategoryExpertSuggestion;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReviewableCategoryExpertSuggestion);
});