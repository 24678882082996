define("discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox", ["exports", "@ember/component", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _object, _service, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <label class="checkbox-label is-category-expert-question">
      <Input
        @type="checkbox"
        @checked={{this.model.is_category_expert_question}}
        {{on "input" this.triggerAppEvent}}
      />
      {{i18n "category_experts.ask_category_expert"}}
    </label>
  {{/if}}
  */
  {
    "id": "WLy6tldq",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,\"label\"],[14,0,\"checkbox-label is-category-expert-question\"],[12],[1,\"\\n    \"],[8,[39,1],[[4,[38,2],[\"input\",[30,0,[\"triggerAppEvent\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"model\",\"is_category_expert_question\"]]]],null],[1,\"\\n    \"],[1,[28,[35,3],[\"category_experts.ask_category_expert\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"input\",\"on\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/components/is-question-checkbox.hbs",
    "isStrictMode": false
  });
  class IsQuestionCheckbox extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    init() {
      super.init(...arguments);
      if ((this.model.creatingTopic || this.model.editingFirstPost) && this.model.topic && this.model.topic.is_category_expert_question) {
        this.set("model.is_category_expert_question", true);
      }
    }
    show(model, category) {
      if (!category || !category.allowingCategoryExpertQuestions) {
        return false;
      }
      return model.editingFirstPost || model.creatingTopic;
    }
    static #_2 = (() => dt7948.n(this.prototype, "show", [(0, _decorators.default)("model", "model.category")]))();
    triggerAppEvent(e) {
      this.appEvents.trigger("category-experts:is-question-checkbox-toggled", {
        checked: e.target.checked
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "triggerAppEvent", [_object.action]))();
  }
  _exports.default = IsQuestionCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IsQuestionCheckbox);
});